import { createReactExtension } from '@backstage/core-plugin-api';
import { searchPlugin } from '@backstage/plugin-search';
import { SearchBarBaseProps } from '@backstage/plugin-search-react';

export const HomePageSearchBarExtension: (
  props: Partial<Omit<SearchBarBaseProps, 'onChange' | 'onSubmit'>>,
) => React.JSX.Element = searchPlugin.provide(
  createReactExtension({
    name: 'HomePageSearchBar',
    component: {
      lazy: () =>
        import('@backstage/plugin-search').then(m => m.HomePageSearchBar),
    },
    data: {
      'home.search.config': {
        layout: {
          height: { maxRows: 1 },
        },
      },
    },
  }),
);
