import {
  createApiFactory,
  createComponentExtension,
  createPlugin,
  createRoutableExtension,
  fetchApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import {
  DefaultDoraMetricsApi,
  doraMetricsApiRef,
} from './apis/doraMetricsApi';

/**
 * doraMetricsApiFactory provide a default way to instantiate the api client for that plugin
 */
export const doraMetricsApiFactory = createApiFactory({
  api: doraMetricsApiRef,
  deps: {
    fetchApi: fetchApiRef,
    identityApi: identityApiRef,
  },
  factory: ({ fetchApi, identityApi }) =>
    new DefaultDoraMetricsApi({
      fetchApi: fetchApi,
      identityApi: identityApi,
    }),
});

export const doraMetricsPlugin = createPlugin({
  id: 'dora-metrics',
  apis: [doraMetricsApiFactory],
  routes: {
    root: rootRouteRef,
  },
});

export const DoraMetricsPage = doraMetricsPlugin.provide(
  createRoutableExtension({
    name: 'DoraMetricsPage',
    component: () =>
      import('./components/DoraMetricsOverviewPage').then(
        m => m.DoraMetricsOverviewPage,
      ),
    mountPoint: rootRouteRef,
  }),
);

export const DoraMetricsGroupCard = doraMetricsPlugin.provide(
  createComponentExtension({
    name: 'DoraMetricsGroupCard',
    component: {
      lazy: () =>
        import('./components/DoraMetricsGroupCard').then(
          m => m.DoraMetricsGroupCard,
        ),
    },
  }),
);
