import {
  CompoundEntityRef,
  Entity,
  stringifyEntityRef,
} from '@backstage/catalog-model';
import {
  createApiRef,
  FetchApi,
  IdentityApi,
} from '@backstage/core-plugin-api';
import { DoraMetricsResults } from '@efg/backstage-plugin-dora-metrics-common';

export interface DoraMetricsApi {
  getEntityMetrics(
    entity: GetEntityMetricsRequest,
  ): Promise<DoraMetricsResults>;
}

export const doraMetricsApiRef = createApiRef<DoraMetricsApi>({
  id: 'dora-metrics.api',
});

export interface GetEntityMetricsRequest {
  entity: Entity | CompoundEntityRef;
}

export class DefaultDoraMetricsApi implements DoraMetricsApi {
  private readonly fetchApi: FetchApi;
  private readonly identityApi: IdentityApi;
  private readonly pluginId: string;

  constructor(options: { fetchApi: FetchApi; identityApi: IdentityApi }) {
    this.fetchApi = options.fetchApi;
    this.identityApi = options.identityApi;
    this.pluginId = 'dora-metrics';
  }

  /**
   * Handles errors when talking to the backend plugin
   */
  private async handleResponse(
    response: Response,
  ): Promise<DoraMetricsResults> {
    if (!response.ok) {
      const payload = await response.text();
      let message;
      switch (response.status) {
        case 404:
          message = `Could not find the ${this.pluginId} Backend (HTTP 404). Make sure the plugin has been fully installed.`;
          break;
        default:
          message = `Request failed with ${response.status} ${response.statusText}, ${payload}`;
      }
      throw new Error(message);
    }

    return (await response.json()) as DoraMetricsResults;
  }

  async getEntityMetrics(
    request: GetEntityMetricsRequest,
  ): Promise<DoraMetricsResults> {
    const baseUrl = `plugin://${this.pluginId}/metrics`;
    const uriTemplate = stringifyEntityRef(request.entity);
    const { token } = await this.identityApi.getCredentials();

    const response = await this.fetchApi.fetch(`${baseUrl}/${uriTemplate}`, {
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      method: 'GET',
    });

    return await this.handleResponse(response);
  }
}
